import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import NewsLetterModal from './news-letter-modal';
import { useFormik } from 'formik';
import React, { useState } from 'react';

const NewsLetterSignup = () => {
    const [isModalActive, setIsModalActive] = useState(false);
    const modalHander = (state) => {
        setIsModalActive(state);
    };
    const formik = useFormik({
        initialValues: { email: '' },
        onSubmit: () => {
            setIsModalActive(true);

            return false;
        }
    }
    );
    const inputProps = {
        type: 'email',
        className: 'input',
        name: 'email',
        id: 'email',
        placeholder: 'Enter your email',
        value: formik.values.email,
        isRequired: true,
        onChangeHandler: formik.handleChange,
    };

    return (
        <div className="constrain-width">
            <div className="newsletter-signup-home banner">
                <div className="newsletter-signup-home-left">
                    <h2>Hear about the latest news & events</h2>
                    <p>Sign up to our newsletter</p>
                    <form onSubmit={formik.handleSubmit} className="newsletter-signup-home-form-inner">
                        <Input {...inputProps}/>
                        <button className="button || default || inverse" type="submit" >Sign up</button>
                    </form>
                    <Link className="newsletter-signup-home-link" to="/page/privacy-policy">
                            Read about our privacy policy
                    </Link>
                </div>
                <div className="newsletter-signup-home-right">
                    <p className="black || text-block">
                        REGULA<br />
                        UPDA<br />
                    </p>
                    <p className="white || text-block">
                        A<br />
                        R<br />
                        T<br />
                    </p>
                    <p className="black || text-block">
                        E
                    </p>
                </div>
            </div>
            <NewsLetterModal modalStatus={isModalActive} modalHander={modalHander} emailValue={formik.values.email}/>
        </div>
    );
};

export default NewsLetterSignup;
